<template>
  <v-container fluid class='align-self-sm-stretch justify-space-between'>
    <v-col class='pa-0 py-3'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <v-icon v-on='on' @click='$emit("close")'>
            mdi-arrow-left-circle
          </v-icon>
        </template>
        {{ $t('BACK') }}
      </v-tooltip>
    </v-col>
    <v-col>
      <v-row>
        <span class='subtitle-2'>Descrição do tipo estoque</span>
      </v-row>
      <v-row>
        <v-text-field v-model='text' />
      </v-row>
    </v-col>
    <v-col class='card-footer'>
      <v-btn
        color='secondary'
        dark
        @click='applyFilter'
      >
        {{ $t('SAVE') }}
      </v-btn>
    </v-col>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        text: null,
      };
    },
    methods: {
      applyFilter () {
        this.$emit('onFilter', { text: this.text });
        this.$emit('applyFilters');
      },
    },
  };

</script>
<style>
  .selected-chip {
    border-color: #0488b9 !important;
    color: #FFFFFF !important;
    background-color: #FFFFFF !important;
  }
  .selected-chip span {
    color: #0488b9 !important;
  }
  .card-footer {
    text-align: end;
  }
</style>
